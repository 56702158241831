import { PropsWithChildren, useEffect } from "react";
import joinClassNames from "classnames";

import CloseIcon from "icons/close.svg?react";

import useChangePathname from "hooks/use-change-pathname";

import { getCSSVariables } from "selectors/common";

import Button from "basics/button";

import Scrollbar from "components/scrollbar";

import Overlay from "../components/overlay";
import Header from "./components/header";
import Body from "./components/body";
import Footer from "./components/footer";

import { Placement } from "./duck/types";
import { useClickOutside, useDialog } from "../duck/hooks";

import classes from "./styles/classes.module.scss";

const DRAWER_PLACEMENT_CLASSES_MAP: Record<Placement, string> = {
  right: classes.rightDrawer,
  bottom: classes.bottomDrawer,
};

interface Props extends Required<PropsWithChildren> {
  isClosable?: boolean;
  onClosed?: VoidFunction;
  className?: string;
  classNames?: Partial<{ drawer: string }>;
  placement?: Placement;
}

const Drawer = ({
  children,
  isClosable = true,
  onClosed,
  className,
  classNames = {},
  placement = "right",
}: Props) => {
  const { isOpened, animationDelay, resolve } = useDialog();

  const closeDrawer = () => resolve(null);

  useChangePathname(closeDrawer);

  const { contentRef, overlayProps } = useClickOutside(closeDrawer);

  useEffect(() => onClosed, []);

  const isBottomPlacement = placement === "bottom";

  return (
    <Overlay
      className={className}
      style={getCSSVariables({ animationDelay: `${animationDelay}ms` })}
      {...overlayProps}
    >
      <Scrollbar
        contentProps={{
          elementRef: element => {
            contentRef.current = element;
          },
          style: { display: "flex", flexDirection: "column" },
        }}
        translateContentSizeYToHolder={isBottomPlacement}
        translateContentSizeXToHolder={placement === "right"}
        style={{ position: "fixed" }}
        className={joinClassNames(
          classes.drawer,
          DRAWER_PLACEMENT_CLASSES_MAP[placement],
          classNames.drawer,
        )}
        role="dialog"
        aria-modal="true"
        trackYOffset={24}
        data-placement={placement}
        data-opened={isOpened}
      >
        {isClosable && (
          <Button
            className={classes.closeButton}
            themeName={isBottomPlacement ? "ghost" : "primary"}
            size={isBottomPlacement ? "sm" : "lg"}
            isIconOnly
            onClick={closeDrawer}
          >
            <CloseIcon className={classes.closeIcon} />
          </Button>
        )}
        {children}
      </Scrollbar>
    </Overlay>
  );
};

Drawer.Header = Header;
Drawer.Body = Body;
Drawer.Footer = Footer;

export default Drawer;
