import { FC, PropsWithChildren } from "react";
import joinClassNames from "classnames";

import ArrowLeftIcon from "icons/arrow-left.svg?react";

import Button from "basics/button";

import classes from "./styles/classes.module.scss";

interface Props extends PropsWithChildren {
  className?: string;
  onBack?: VoidFunction;
}

const Header: FC<Props> = ({ children, className, onBack }) => (
  <div className={joinClassNames(classes.wrapper, className)}>
    {onBack && (
      <Button
        className={classes.backButton}
        isIconOnly
        themeName="ghost"
        onClick={onBack}
      >
        <ArrowLeftIcon className={classes.arrowLeftIcon} />
      </Button>
    )}
    {children}
  </div>
);

export default Header;
